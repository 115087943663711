import { useContext } from "react";
import { ThemeContext } from "../context/theme";

export default function useLogo(type: "transparent" | "inline-transparent") {
  const { theme } = useContext(ThemeContext);

  switch (type) {
    case "transparent":
      return `/logo_transparent_${theme}.svg`;
    case "inline-transparent":
      return `/logo_transparent_banner_${theme}.png`;

    default:
      return "/logo_transparent_light.svg";
  }
}
