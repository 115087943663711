import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AxiosError } from "axios";
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import Page from "../../../components/Page";
import useAxios from "../../../hooks/use-axios";
import {
  EmptyErrors,
  Errors,
  fieldNameHasError,
  getAlertForFieldName,
  getErrorForFieldName,
  makeServerError,
} from "../../../types/api/common";
import { Log } from "../../../types/api/logs";
import Layout from "../layout";

const MAX_FILE_UPLOAD_SIZE = 4000 * 1000; // bytes
const MAX_FILES = 5;

export function Dropzone({
  errors,
  files,
  loading,
  onError,
  onFilesChanged,
  onFileRemoved,
}: {
  errors: Errors;
  files: Map<string, File>;
  loading: boolean;
  onError: (errors: Errors) => void;
  onFilesChanged: (files: File[]) => void;
  onFileRemoved: (file: File) => void;
}) {
  const theme = useTheme();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    validator: (file) => {
      if (file.size > MAX_FILE_UPLOAD_SIZE) {
        return {
          code: "file-too-large",
          message: `The file was larger than ${MAX_FILE_UPLOAD_SIZE / 1000}kb.`,
        };
      }

      if (files.size >= MAX_FILES && !files.has(file.name)) {
        return {
          code: "too-many-files",
          message: `You have exceeded your allowed threshold of ${MAX_FILES} files.`,
        };
      }

      return null;
    },
    maxFiles: MAX_FILES,
    accept: {
      "text/csv": [".csv"],
    },
    onDropAccepted(files: File[], event) {
      onError(EmptyErrors);
      onFilesChanged(files);
    },
    onDropRejected(fileRejections: FileRejection[], event) {
      const makeErrorMessage = (fn?: string, message?: string) => {
        return `${fn} - ${message || "There was a problem handling"}`;
      };

      onError({
        message: "",
        errors: {
          files: fileRejections.map((fr: FileRejection) =>
            makeErrorMessage(fr.file.name, fr.errors.at(0)?.message)
          ),
        },
      });
    },
    disabled: loading,
  });

  const fileList = useMemo(() => {
    const components: React.ReactNode[] = [];
    let idx = 0;

    files.forEach((file: File) => {
      components.push(
        <ListItem
          key={idx}
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="comments"
              onClick={() => onFileRemoved(file)}
            >
              <DeleteIcon />
            </IconButton>
          }
        >
          <ListItemText primary={file.name} />
        </ListItem>
      );
      idx++;
    });

    return components;
  }, [files, onFileRemoved]);

  const getFilesErrors = useCallback(() => {
    if (errors === undefined || errors.errors === undefined) {
      return [];
    }

    const keys = Object.keys(errors.errors).filter((key) =>
      key.includes("files")
    );

    return keys.map((key: string, idx: number) => (
      <Alert severity="error" key={idx}>
        {errors.errors[key][0]}
      </Alert>
    ));
  }, [errors]);

  return (
    <Card
      raised
      sx={{
        background:
          theme.palette.mode === "dark"
            ? theme.palette.grey[900]
            : theme.palette.grey[100],
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.grey[300]
            : theme.palette.grey[500],
        borderWidth: 1,
        borderStyle: "dashed",
      }}
      elevation={isDragActive ? 10 : 0}
    >
      <CardContent {...getRootProps()} sx={{ padding: 0 }}>
        <Grid2 textAlign="center" padding={8}>
          <input {...getInputProps()} name="files" required />
          <Typography variant="caption">
            Click or drag here to attach logs.
          </Typography>
        </Grid2>
      </CardContent>
      <Grid2 size={{ xs: 12 }}>
        {fileList.length > 0 && (
          <List sx={{ bgcolor: "background.paper" }} dense>
            {fileList}
          </List>
        )}
      </Grid2>
      <Grid2 size={{ xs: 12 }}>{getFilesErrors()}</Grid2>
    </Card>
  );
}

const DEFAULT_FORM_STATE = {
  name: "",
  notes: "",
  public: 0,
};

export default function UploadLogs() {
  const axios = useAxios();
  const navigate = useNavigate();
  const [form, setForm] = useState(DEFAULT_FORM_STATE);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>(EmptyErrors);
  const [files, setFiles] = useState<Map<string, File>>(new Map());

  const getAlertForField = useCallback(
    (fieldName: string) => getAlertForFieldName(errors, fieldName),
    [errors]
  );

  const handleCheckbox = (el: ChangeEvent<HTMLInputElement>) => {
    el.preventDefault();

    setForm((prev) => ({
      ...prev,
      [el.target.name]: el.target.checked ? 1 : 0,
    }));
  };

  const handleChange = (el: ChangeEvent<HTMLInputElement>) => {
    el.preventDefault();

    setForm((prev) => ({
      ...prev,
      [el.target.name]: el.target.value,
    }));
  };

  const onFilesChanged = (files: File[]) => {
    setFiles((prev) => {
      const cpy = new Map(prev);
      files.forEach((file: File) => cpy.set(file.name, file));
      return cpy;
    });
  };

  const onFileRemoved = (file: File) => {
    setFiles((prev) => {
      const cpy = new Map(prev);
      cpy.delete(file.name);
      return cpy;
    });
  };

  const onError = (errors: Errors) => {
    setErrors(errors);
  };

  const handleSubmit = (el: FormEvent<HTMLFormElement>) => {
    el.preventDefault();
    setLoading(true);
    setErrors(EmptyErrors);

    const data = new FormData(el.target as HTMLFormElement);
    // we are managing our own state, reset files
    data.delete("public");
    data.delete("files");
    // append each file from our state
    files.forEach((file: File) => {
      data.append("files[]", file);
    });

    data.append("public", JSON.stringify(form.public));

    axios()
      .post("/v1/logs", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }: { data: Log }) => {
        navigate(`/logs/${data.id}`);
      })
      .catch((error: AxiosError) => {
        if (error.status !== undefined && error.response !== undefined) {
          switch (error.status) {
            case 422:
            case 400:
              setErrors(error.response.data as Errors);
              break;
            case 500:
              setErrors(
                makeServerError(
                  "We encountered an error uploading your files, please try again in a bit."
                )
              );
              break;
          }
        }

        setLoading(false);
      });
  };

  return (
    <Layout>
      <Page title="Analyze Log">
        <Grid2 container spacing={1} flexGrow={1}>
          <Grid2 size={{ xs: 12 }}>
            <Card variant="outlined">
              <CardContent>
                <form
                  onSubmit={handleSubmit}
                  aria-label="form"
                  id="upload-log-form"
                >
                  <Grid2 container spacing={1}>
                    <Grid2 container flexGrow={1} paddingY={1} spacing={1}>
                      <Grid2>
                        <Typography variant="body1">Settings</Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <TextField
                          type="text"
                          name="name"
                          label="Log Name"
                          placeholder="Log Name"
                          title="Log Name"
                          onChange={handleChange}
                          value={form.name}
                          required
                          disabled={loading}
                          error={fieldNameHasError(errors as Errors, "name")}
                        />
                      </Grid2>
                      {getAlertForField("name")}
                      <Grid2 size={{ xs: 12 }}>
                        <TextField
                          multiline
                          minRows={5}
                          title="Log Notes"
                          label="Log Notes"
                          name="notes"
                          onChange={handleChange}
                          value={form.notes}
                          placeholder="Log Notes (optional)"
                          disabled={loading}
                          error={fieldNameHasError(errors as Errors, "notes")}
                        />
                      </Grid2>
                      {getAlertForField("notes")}
                      <Grid2 size={{ xs: 12 }}>
                        <Divider textAlign="left">
                          <Typography variant="body1">Features</Typography>
                        </Divider>
                      </Grid2>
                      <Grid2 container spacing={1} flexGrow={1}>
                        <Grid2 size={{ sm: 6, md: 4 }}>
                          <Tooltip
                            title="Viewable by anyone."
                            placement="right"
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={form.public === 1}
                                  onChange={handleCheckbox}
                                  name="public"
                                  disabled={loading}
                                />
                              }
                              label="Public"
                            />
                          </Tooltip>
                        </Grid2>
                      </Grid2>
                      <Grid2 container size={{ xs: 12 }}>
                        <Grid2 size={{ xs: 12 }}>
                          <Typography variant="body1">Files</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                          <Dropzone
                            loading={loading}
                            errors={errors as Errors}
                            files={files}
                            onError={onError}
                            onFilesChanged={onFilesChanged}
                            onFileRemoved={onFileRemoved}
                          />
                        </Grid2>
                      </Grid2>
                    </Grid2>
                    {fieldNameHasError(errors, "server") && (
                      <Grid2 size={{ xs: 12 }}>
                        <Alert severity="error">
                          {getErrorForFieldName(errors, "server")}
                        </Alert>
                      </Grid2>
                    )}
                    <Grid2 size={{ xs: 12 }}>
                      <LoadingButton
                        disabled={loading || files.size < 1}
                        loading={loading}
                        variant="contained"
                        type="submit"
                      >
                        Upload
                      </LoadingButton>
                    </Grid2>
                  </Grid2>
                </form>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Page>
    </Layout>
  );
}
