import { CssBaseline, ThemeProvider } from "@mui/material";
import Cookie from "js-cookie";
import { createContext, useState } from "react";
import { darkTheme, lightTheme } from "../theme/theme";

export const ThemeContext = createContext<{
  theme: string;
  setTheme: (theme: string) => void;
}>({
  theme: "light",
  setTheme: () => {},
});

const THEME_COOKIE = "calibratly-theme";
export default function MaterialThemeProvider(props: React.PropsWithChildren) {
  const [theme, setThemeValue] = useState(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      return "dark";
    }

    return "light";
  });

  const setTheme = (theme: string) => {
    Cookie.set(THEME_COOKIE, theme);
    setThemeValue(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}
