import * as React from "react";
import ActionMap from "./actionmap";

interface SearchState {
  query: string;
}

export enum SearchActionTypes {
  SetQuery = "set-query",
}

interface SearchPayload {
  [SearchActionTypes.SetQuery]: {
    query: string;
  };
}

type SearchActions = ActionMap<SearchPayload>[keyof ActionMap<SearchPayload>];
const reducer = (state: SearchState, action: SearchActions) => {
  switch (action.type) {
    case SearchActionTypes.SetQuery:
      return { ...state, query: action.payload.query };
    default:
      return state;
  }
};

const initialState = {
  query: "",
};

export const SearchContext = React.createContext<{
  state: SearchState;
  dispatch: React.Dispatch<SearchActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const SearchProvider = function ({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <SearchContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchContext.Provider>
  );
};
