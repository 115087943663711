import { Link as MuiLink, LinkProps } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface StyledLinkProps {
  to: string;
}

export default function Link(props: StyledLinkProps & LinkProps) {
  const navigate = useNavigate();
  const { to, ...rest } = props;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate(to);
  };

  return <MuiLink sx={{ cursor: "pointer" }} onClick={handleClick} {...rest} />;
}
