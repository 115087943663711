import { RefreshRounded } from "@mui/icons-material";
import { Button, Grid2, Typography } from "@mui/material";
import Page from "../../components/Page";

export default function Error() {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Page variant="utility" pageTitle="Something Went Wrong :(">
      <Grid2 textAlign="center">
        <Grid2>
          <Typography variant="h1">Oh no!</Typography>
        </Grid2>
        <Grid2>
          <Typography variant="body1">
            Something went wrong on our end loading the app, please try again
            later.
          </Typography>
        </Grid2>
        <Grid2>
          <Button onClick={handleRefresh} variant="contained">
            <RefreshRounded />
            Retry
          </Button>
        </Grid2>
      </Grid2>
    </Page>
  );
}
