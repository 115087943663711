import { LoadingButton } from "@mui/lab";
import { Alert, Grid2, TextField, TextFieldProps } from "@mui/material";
import { AxiosError } from "axios";
import { Fragment, useState } from "react";
import useAxios from "../hooks/use-axios";
import {
  EmptyErrors,
  Errors,
  getErrorForFieldName,
  handleErrors,
} from "../types/api/common";

interface Field<S> {
  props: TextFieldProps & {
    name: keyof S;
    type: string;
  };
  gridWidth?: number;
  validate?: (state: S) => string | null;
}

export interface FormProps<T, S> {
  url: string;
  method: "patch" | "post" | "put";
  fields: Field<S>[];
  onSuccess?: (data: T) => void;
  onError?: (error: AxiosError) => void;
  notAllowedText?: string;
  successMessage?: string;
  initialState?: S;
  validate?: (state: S) => boolean;
}

export default function Form<T, S>(props: FormProps<T, S>) {
  const axios = useAxios();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState<Errors>(EmptyErrors);
  const [form, setForm] = useState<S>(props.initialState || ({} as S));

  const handleInputChange = (el: React.ChangeEvent<HTMLInputElement>) => {
    el.preventDefault();
    setForm({
      ...form,
      [el.target.name]: el.target.value,
    });
  };

  const clearPasswords = () => {
    const toClear: { [key: string]: string } = {};
    props.fields.forEach((field) => {
      if (field.props.type === "password") {
        toClear[field.props.name] = "";
      }
    });

    setForm({
      ...form,
      ...toClear,
    });
  };

  const handleFormSubmit = (el: React.FormEvent<HTMLFormElement>) => {
    el.preventDefault();
    setLoading(true);
    setDone(false);
    setError(EmptyErrors);

    axios()
      .request({
        url: props.url,
        method: props.method,
        data: form,
      })
      .then(({ data }: { data: T }) => {
        setDone(true);
        setLoading(false);
        if (props.onSuccess !== undefined) {
          props.onSuccess(data);
        }
      })
      .catch((error: AxiosError) => {
        handleErrors(
          setError,
          props.notAllowedText || "You are not allowed to perform this action."
        )(error);
        setLoading(false);

        if (props.onError !== undefined) {
          props.onError(error);
        }
      })
      .finally(clearPasswords);
  };

  return (
    <form aria-label="form" onSubmit={handleFormSubmit}>
      <Grid2 container flexGrow={1} paddingY={1} spacing={1}>
        {props.fields.map((field, idx) => {
          if (field.props.name === undefined) {
            return null;
          }

          const fieldValidated = field.validate && field.validate(form);
          const fieldError = getErrorForFieldName(error, field.props.name);

          return (
            <Fragment key={idx}>
              <Grid2 size={field.gridWidth} paddingY={1}>
                <TextField
                  {...field.props}
                  value={form[field.props.name]}
                  variant="standard"
                  onChange={handleInputChange}
                  disabled={loading}
                  error={!!fieldError || !!fieldValidated}
                />
                {(!!fieldValidated || !!fieldError) && (
                  <Grid2 size={12} paddingY={1}>
                    {!!fieldValidated && (
                      <Alert severity="error">{fieldValidated}</Alert>
                    )}
                    {fieldError && <Alert severity="error">{fieldError}</Alert>}
                  </Grid2>
                )}
              </Grid2>
            </Fragment>
          );
        })}

        {getErrorForFieldName(error, "server") && (
          <Grid2 size={12} paddingY={1}>
            <Alert severity="error">
              {getErrorForFieldName(error, "server")}
            </Alert>
          </Grid2>
        )}

        {done && props.successMessage && (
          <Grid2 size={12}>
            <Alert severity="success">{props.successMessage}</Alert>
          </Grid2>
        )}

        <Grid2 size={12} paddingY={1}>
          <LoadingButton
            type="submit"
            size="small"
            variant="contained"
            loading={loading}
            disabled={
              loading || (props.validate !== undefined && !props.validate(form))
            }
          >
            Update
          </LoadingButton>
        </Grid2>
      </Grid2>
    </form>
  );
}
