import dayjs from "dayjs";
import { createContext, ReactNode, useState } from "react";
import { User } from "../types/api/user";

export const AUTH_STORAGE_KEY = "retune-auth";

export interface AuthState {
  token: string;
  expires: dayjs.Dayjs;
  user: User;
}

export const DEFAULT_AUTH_STATE: AuthState = {
  token: "",
  expires: dayjs(),
  user: {
    id: "",
    username: "",
    email: "",
    created_at: new Date(),
    updated_at: new Date(),
  },
};

export const AuthContext = createContext<{
  auth: AuthState;
  setAuth: React.Dispatch<AuthState>;
}>({
  auth: DEFAULT_AUTH_STATE,
  setAuth: () => {},
});

export const AuthProvider = function ({ children }: { children: ReactNode }) {
  const [auth, setAuth] = useState<AuthState>(() => {
    try {
      const value = window.localStorage.getItem(AUTH_STORAGE_KEY);
      if (!value) {
        throw new Error("Failed to get read auth state from local storage.");
      }

      const state: AuthState = JSON.parse(value);
      if (dayjs(state.expires).isBefore(dayjs())) {
        throw new Error("Local storage contained expired auth information.");
      }

      return state;
    } catch (error) {
      return DEFAULT_AUTH_STATE;
    }
  });

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
