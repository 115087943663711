import { Close, DoDisturb, Search } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { AxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useParams, useSearchParams } from "react-router-dom";
import Page from "../../../components/Page";
import useAxios from "../../../hooks/use-axios";
import useViewportResize, { calcMaxHeight } from "../../../hooks/use-viewport";
import { File } from "../../../types/api/files";
import { Log } from "../../../types/api/logs";
import Layout from "../layout";
import ChartContainer from "./ChartContainer";

interface LogState {
  loading: boolean;
  data: Log;
}

interface FileState {
  loading: boolean;
  data: File;
}

const InitialLogState = {
  loading: true,
  data: {} as Log,
};

const InitialFileState = {
  loading: true,
  data: {} as File,
};

interface FileSelectorProps {
  files: File[];
  selected?: File;
  onSelected: (file: File) => void;
}

function FileSelector(props: FileSelectorProps) {
  const { files, selected, onSelected } = props;

  const handleChange = (event: SelectChangeEvent<string>) => {
    const file = files.find((file: File) => file.id === event.target.value);

    if (file !== undefined) {
      onSelected(file);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="log-file-selector-label">File</InputLabel>
      <Select
        labelId="log-file-selector-label"
        id="log-file-selector"
        value={selected?.id}
        label="File"
        onChange={handleChange}
        variant="outlined"
      >
        {files.map((file) => (
          <MenuItem key={file.id} value={file.id}>
            {file.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

interface LogErrorProps {
  error: AxiosError;
}

function LogError(props: LogErrorProps) {
  const { error } = props;
  const theme = useTheme();

  if (error.code === "ERR_CANCELED") {
    return null;
  }

  const getErrorText = () => {
    switch (error.status) {
      case 403:
        return {
          Icon: DoDisturb,
          text: "Sorry, you're not allowed to access this log.",
        };
      case 404:
        return {
          Icon: Search,
          text: "Sorry, the log you're looking for does not exist.",
        };
      default:
        return {
          Icon: Close,
          text: "Something went wrong, please try again later.",
        };
    }
  };

  const { Icon, text } = getErrorText();

  return (
    <Grid2
      size={{ xs: 12 }}
      justifyContent="center"
      alignItems="center"
      display="inline-flex"
    >
      <Icon
        fontSize="large"
        color="error"
        sx={{ marginRight: theme.spacing(1) }}
      />
      <Typography variant="body1">{text}</Typography>
    </Grid2>
  );
}

function FileProcessing() {
  const theme = useTheme();

  return (
    <Grid2
      size={{ xs: 12 }}
      justifyContent="center"
      alignItems="center"
      display="inline-flex"
    >
      <CircularProgress
        size={30}
        variant="indeterminate"
        sx={{ marginRight: theme.spacing(1) }}
      />
      <Typography variant="body1">This file is still processing...</Typography>
    </Grid2>
  );
}

export default function Logs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { logId } = useParams();
  const theme = useTheme();
  const axios = useAxios();
  const [logState, setLogState] = useState<LogState>(InitialLogState);
  const [fileState, setFileState] = useState<FileState>(InitialFileState);
  const [selected, setSelected] = useState<File>();
  const [error, setError] = useState<AxiosError>();
  const [maxHeight, setMaxHeight] = useState(
    calcMaxHeight(window.visualViewport, 400)
  );
  const selectedFileRef = useRef(searchParams.get("file"));

  useViewportResize((viewport) => {
    setMaxHeight(calcMaxHeight(viewport, 400));
  });

  const getFile = useCallback(
    (signal: AbortSignal) => {
      return axios(signal)
        .get(`/v1/files/${selected?.id}`)
        .then(({ data }: { data: File }) => {
          setFileState({
            loading: false,
            data,
          });
        })
        .catch((error: AxiosError) => {
          if (error.code === "ERR_CANCELED") {
            return;
          }

          flushSync(() => {
            setFileState({
              loading: false,
              data: {} as File,
            });
            setError(error);
          });
        });
    },
    [selected, axios]
  );

  const handleFileSelected = (file: File) => {
    setSearchParams((prev) => {
      prev.set("file", file.id);
      return prev;
    });
    setSelected(file);
  };

  useEffect(() => {
    setLogState({
      loading: true,
      data: {} as Log,
    });
    setError(undefined);

    const controller = new AbortController();

    axios(controller.signal)
      .get(`/v1/logs/${logId}`)
      .then(({ data }: { data: Log }) => {
        flushSync(() => {
          setSelected(
            data.files.find((file) => file.id === selectedFileRef.current) ||
              data.files[0]
          );
          setLogState({
            loading: false,
            data,
          });
        });
      })
      .catch((error: AxiosError) => {
        flushSync(() => {
          setError(error);
          setLogState({
            loading: false,
            data: {} as Log,
          });
        });
      });
  }, [axios, logId]);

  useEffect(() => {
    if (selected === undefined) {
      return;
    }

    setFileState({
      loading: true,
      data: {} as File,
    });
    setError(undefined);

    const controller = new AbortController();
    getFile(controller.signal);

    return () => {
      controller.abort();
    };
  }, [getFile, selected]);

  useEffect(() => {
    if (fileState.data.processed_at === null) {
      const controller = new AbortController();
      const interval = setInterval(() => {
        getFile(controller.signal);
      }, 2000);

      return () => {
        controller.abort();
        clearInterval(interval);
      };
    }
  }, [getFile, fileState.data.processed_at]);

  return (
    <Layout>
      <Page
        loading={logState.loading}
        pageTitle={logState.data.name || "Log"}
        title={logState.data.name || "Log"}
        action={
          <Box sx={{ maxWidth: `calc(100vw - ${theme.spacing(2)})` }}>
            <FileSelector
              files={logState.data.files || []}
              selected={selected}
              onSelected={handleFileSelected}
            />
          </Box>
        }
      >
        {!error && (!!fileState.data.processed_at || fileState.loading) && (
          <ChartContainer
            loading={fileState.loading}
            file={fileState.data}
            maxHeight={maxHeight}
          />
        )}
        {((!logState.loading && !!error) ||
          (!fileState.loading && !fileState.data.processed_at)) && (
          <Box
            component={Paper}
            elevation={0}
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[900]
                  : theme.palette.grey[100],
              borderRadius: 2,
              width: "100%",
              height: maxHeight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!error && <FileProcessing />}
            {!!error && <LogError error={error} />}
          </Box>
        )}
      </Page>
    </Layout>
  );
}
