import { ListItem, ListItemText } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { File } from "../../types/api/files";

export default function LogFileItem({
  file,
  action,
}: {
  file: File;
  action?: ReactNode;
}) {
  const status: { color: "primary" | "success"; label: string } =
    useMemo(() => {
      switch (file.processed_at) {
        case null:
          return { color: "primary", label: "processing" };
        default:
          return { color: "success", label: "processed" };
      }
    }, [file.processed_at]);

  return (
    <ListItem secondaryAction={action}>
      <ListItemText
        secondary={status.label}
        secondaryTypographyProps={{
          color: status.color,
        }}
        primary={file.name}
      />
    </ListItem>
  );
}
