import { createTheme } from "@mui/material";

const componentOverrides = {
  MuiTextField: {
    styleOverrides: {
      root: {
        width: "100%",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 2,
      },
    },
  },
};

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
  components: {
    ...componentOverrides,
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    ...componentOverrides,
  },
});

export { lightTheme, darkTheme };
