import { Box, useTheme } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";

interface MainContentProps {
  drawerWidth: number;
  children: React.ReactNode;
}

export default function MainContent(props: MainContentProps) {
  const theme = useTheme();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        paddingY: 1,
        paddingX: 1,
        width: { md: `calc(100vw - ${props.drawerWidth}px)` },
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Toolbar />
      {props.children}
    </Box>
  );
}
