import React from "react";
import ReactDOM from "react-dom/client";
import "./app.css";
import { AuthProvider } from "./context/auth";
import MaterialThemeProvider from "./context/theme";
import Router from "./router";

function App() {
  return (
    <MaterialThemeProvider>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </MaterialThemeProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
