import { ReactNode } from "react";
import Container from "./Container";

export default function Utility({ children }: { children?: ReactNode }) {
  return (
    <Container
      container
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Container>
  );
}
