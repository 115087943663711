import { CancelOutlined, Check } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertColor,
  Button,
  Grid2,
  Snackbar,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Page from "../../../components/Page";
import { UserContext } from "../../../context/user";
import useAxios from "../../../hooks/use-axios";
import Loading from "../../utilities/loading";

export default function VerifyEmail() {
  const axios = useAxios();
  const { user_id, hash } = useParams();
  const [params] = useSearchParams();
  const { user, refresh } = useContext(UserContext);

  /** Validate Good Request State */
  const [validateState, setValidateState] = useState({
    loading: true,
    done: false,
    verified: false,
  });

  /** Request Verify Email State */
  const [newUrlState, setNewUrlState] = useState({
    loading: false,
  });

  /** Snackbar State */
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    severity: AlertColor;
    message: string;
  }>({
    open: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    if (validateState.done) {
      return;
    }

    if (
      !user_id ||
      !hash ||
      !params.has("expires") ||
      !params.has("signature") ||
      !!user.email_verified_at ||
      validateState.done
    ) {
      setValidateState((prevState) => ({
        ...prevState,
        loading: false,
        verified: !!user.email_verified_at,
      }));
      return;
    }

    const controller = new AbortController();

    axios(controller.signal)
      .post(
        `/v1/user/email/verify/${user_id}/${hash}?expires=${params.get("expires")}&signature=${params.get("signature")}`
      )
      .then(() => {
        refresh();
        setValidateState({
          loading: false,
          verified: true,
          done: true,
        });
      })
      .catch((e: AxiosError) => {
        if (e.code === "ERR_CANCELED") {
          return;
        }

        let error;
        switch (e.status) {
          case 403:
            error =
              "Uh oh, we could not validate the provided URL, try requesting a new one.";
            break;
          default:
            error = "An unknown error occurred, please try again later.";
        }

        setSnackbar({
          open: true,
          severity: "error",
          message: error,
        });

        setValidateState({
          loading: false,
          verified: false,
          done: true,
        });
      });

    return () => {
      controller.abort();
    };
  }, [
    user.email_verified_at,
    validateState.done,
    user_id,
    hash,
    params,
    axios,
    refresh,
  ]);

  useEffect(() => {
    if (user.email_verified_at) {
      return;
    }

    const interval = setInterval(refresh, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [refresh, user.email_verified_at]);

  const handleResendClick = () => {
    if (newUrlState.loading) {
      return;
    }

    axios()
      .post("/v1/user/email/verify/send")
      .then(() => {
        setSnackbar({
          open: true,
          severity: "success",
          message: "Successfully emailed verification link.",
        });
      })
      .catch((e: AxiosError) => {
        let error;
        switch (e.status) {
          case 429:
            error = "Slow down! You've requested a new URL too recently.";
            break;
          default:
            error = "An unknown error occurred, please try again later.";
        }

        setSnackbar({
          open: true,
          severity: "error",
          message: error,
        });
      })
      .finally(() => {
        setNewUrlState({
          loading: false,
        });
      });
  };

  if (validateState.loading) {
    return <Loading />;
  }

  return (
    <Page pageTitle="Verify Email" variant="utility">
      <Grid2 container spacing={1} justifyContent="center" alignItems="center">
        <Grid2 size={{ xs: 12 }} justifyContent="center" textAlign="center">
          {!user.email_verified_at && !validateState.verified ? (
            <>
              <Typography variant="h1" color="error">
                <CancelOutlined fontSize="inherit" />
              </Typography>
              <Typography variant="h1">Email Not Verified</Typography>
            </>
          ) : (
            <>
              <Typography variant="h1" color="success">
                <Check fontSize="inherit" />
              </Typography>
              <Typography variant="h1">Email Verified</Typography>
            </>
          )}
        </Grid2>

        <Grid2 size={{ xs: 12 }} justifyContent="center" textAlign="center">
          {!user.email_verified_at && !validateState.verified ? (
            <Typography variant="body1">
              Please click the link we sent to{" "}
              <a href={`mailto:${user.email}`}>{user.email}</a> verify your
              email.
              <br />
              This helps us validate you are a real person and to protect your
              account.
            </Typography>
          ) : (
            <Typography variant="body1">
              Congratulations your email is verified!
              <br />
              <Link to="/dashboard">
                <Button>Go Home</Button>
              </Link>
            </Typography>
          )}
        </Grid2>

        {!user.email_verified_at && !validateState.verified && (
          <Grid2
            size={{ xs: 12 }}
            container
            justifyContent="center"
            spacing={1}
            paddingTop={1}
          >
            <Grid2>
              <LoadingButton
                loading={newUrlState.loading}
                disabled={newUrlState.loading}
                onClick={handleResendClick}
                variant="contained"
                id="resend-verification-email-button"
              >
                Resend Verification
              </LoadingButton>
            </Grid2>
            <Grid2>
              <Link to="/user/settings">
                <Button variant="outlined">Change Email</Button>
              </Link>
            </Grid2>
          </Grid2>
        )}
      </Grid2>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={() => {
          setSnackbar({
            open: false,
            severity: "success",
            message: "",
          });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Page>
  );
}
