import { useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import { NormalizedParam } from "../hooks/use-log-params";
import "../styles/LogChart.css";
import { Series } from "../types/api/series";
import { randomColor } from "../util/color";

export interface LogChartProps {
  data: Series;
  params: NormalizedParam[];
  autoScale?: boolean;
  xAxis?: NormalizedParam;
  containerProps?: {
    aspect?: number;
    width?: number | string;
    height?: number | string;
    maxHeight?: number;
    maxWidth?: number | string;
  };
  legend?: boolean;
  syncId?: string;
}

export default function LogChart({
  data,
  params,
  autoScale,
  xAxis,
  containerProps,
  legend,
  syncId,
}: LogChartProps) {
  const theme = useTheme();
  const colors = useMemo(() => {
    const colors: string[] = [];

    if (data.length < 1) {
      return colors;
    }

    for (let i = 0; i < Object.keys(data[0]).length; i++) {
      colors.push(randomColor());
    }

    return colors;
  }, [data]);
  const [hover, setHover] = useState<string | undefined>();
  const [hidden, setHidden] = useState<string[]>([]);

  const handleLegendMouseEnter = (e: Payload) => {
    setHover(String(e.dataKey));
  };

  const handleLegendMouseLeave = () => {
    setHover(undefined);
  };

  const handleLegendClick = (e: Payload) => {
    const key = String(e.dataKey);
    if (hidden.includes(key)) {
      return setHidden(hidden.filter((v) => v !== key));
    }

    setHidden([...hidden, key]);
  };

  return (
    <ResponsiveContainer {...containerProps}>
      <LineChart data={data} syncId={syncId}>
        {params.map((param, idx) => (
          <Fragment key={param.key}>
            <Line
              type="monotone"
              yAxisId={autoScale ? param.key : undefined}
              dataKey={param.key}
              name={param.name}
              stroke={colors[idx]}
              strokeWidth={hover === param.key ? 4 : 2}
              dot={false}
              activeDot
              hide={hidden.includes(param.key)}
              legendType="plainline"
            />
            {/**
             * watch out for this, might have 2 params scale differently in an undesireable manner
             * for example; put SP being higher than PUT even though PUT is higher than the SP.
             */}
            {autoScale && (
              <YAxis
                yAxisId={param.key}
                dataKey={param.key}
                domain={["dataMin", "auto"]}
                hide
              />
            )}
          </Fragment>
        ))}
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        {xAxis !== undefined && (
          <XAxis
            name={xAxis.name}
            dataKey={xAxis.key}
            domain={["dataMin", "dataMax"]}
            tickFormatter={(value) => {
              const parsed = parseInt(value);
              if (Number.isNaN(parsed)) {
                return value;
              }

              return Math.round(parsed);
            }}
          />
        )}
        {legend && (
          <Legend
            onClick={handleLegendClick}
            onMouseOver={handleLegendMouseEnter}
            onMouseOut={handleLegendMouseLeave}
          />
        )}
        <Tooltip
          contentStyle={{
            backgroundColor: theme.palette.background.default,
          }}
          labelFormatter={(label: number) => {
            return `${Math.round(label * 100) / 100} ${xAxis?.name || ""}`;
          }}
          formatter={(value: number) => Math.round(value * 100) / 100}
        />
        {!autoScale && <YAxis mirror domain={["dataMin", "auto"]} />}
      </LineChart>
    </ResponsiveContainer>
  );
}
