import { AxiosResponse } from "axios";
import { useCallback } from "react";
import useAuth from "./use-auth";

export default function useUnauthInterceptor() {
  const { setUnauthed } = useAuth();

  const interceptor = useCallback(
    (response: AxiosResponse) => {
      if (response.status === 401) {
        setUnauthed();
      }

      return Promise.reject(response);
    },
    [setUnauthed]
  );

  return interceptor;
}
