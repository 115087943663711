import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

interface DeleteDialogProps {
  title?: ReactNode;
  body?: ReactNode;
  handleCancel: () => void;
  handleConfirm: () => void;
}

export default function DeleteDialog(props: DeleteDialogProps & DialogProps) {
  const { open, title, handleConfirm, handleCancel, body, ...rest } = props;
  const [acknowledged, setAcknowledged] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    setAcknowledged(false);
    setConfirmed(false);
  }, [open]);

  const confirm = () => {
    setConfirmed(true);
    handleConfirm();
  };

  const handleCheckbox = () => {
    setAcknowledged(!confirmed);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...rest}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers>
        {body && body}
        <FormControl>
          <FormControlLabel
            label="I Acklowledge"
            control={
              <Checkbox
                checked={acknowledged}
                onChange={() => handleCheckbox()}
                id="confirmation-checkbox"
              />
            }
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button disabled={confirmed} autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          disabled={!acknowledged}
          loading={confirmed}
          onClick={confirm}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
