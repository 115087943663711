import { Search } from "@mui/icons-material";
import { Masonry } from "@mui/lab";
import {
  Alert,
  Box,
  Grid2,
  InputAdornment,
  Pagination,
  Paper,
  Skeleton,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useContext, useMemo } from "react";
import Page from "../../components/Page";
import Link from "../../components/StyledLink";
import { DashboardContext } from "../../context/dashboard";
import { getFirstError, hasErrors } from "../../types/api/common";
import { Log } from "../../types/api/logs";
import Layout from "./layout";
import LogCard from "./LogCard";

function SearchLogs({
  value,
  onChange,
}: {
  value: string | null;
  onChange: (el: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <TextField
      id="input-with-icon-textfield"
      title="Search..."
      placeholder="Search..."
      onChange={onChange}
      value={value}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        },
      }}
      variant="outlined"
    />
  );
}

export default function Dashboard() {
  const theme = useTheme();
  const {
    query,
    logState: { loading, errors, page },
    setPage,
    setParams,
    setQuery,
    updateLog,
    deleteLog,
  } = useContext(DashboardContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  const handleQueryChange = (el: ChangeEvent<HTMLInputElement>) => {
    setParams((prev: URLSearchParams) => {
      prev.set("query", el.target.value);
      return prev;
    });

    setQuery(el.target.value);
  };

  const handlePageChange = (page: number) => {
    setParams((prev: URLSearchParams) => {
      prev.set("page", page.toString());
      return prev;
    });

    setPage(page);
  };

  const logCards = useMemo(
    () =>
      page.data.map((log: Log) => (
        <LogCard
          key={log.id}
          variant="outlined"
          log={log}
          updateLog={updateLog}
          deleteLog={deleteLog}
        />
      )),
    [page.data, deleteLog, updateLog]
  );

  const loadingNodes = useMemo(
    () =>
      [1, 2, 3, 4, 5, 6].map((v) => (
        <Grid2 key={v} size={{ xs: 12, md: 6, lg: 4 }}>
          <Skeleton variant="rounded" width="100%" height={300} />
        </Grid2>
      )),
    []
  );

  const getColumns = () => {
    if (isMobile) {
      return 1;
    }

    if (isMedium) {
      return 2;
    }

    return 3;
  };

  const renderState = useMemo(() => {
    const errs = hasErrors(errors);

    return {
      empty: !loading && !errs && page.data.length < 1,
      logs: !loading && !errs && page.data.length > 0,
      // TODO: find out why these race
      loading: loading && !errs,
      errors: !loading && errs,
    };
  }, [loading, errors, page.data]);

  return (
    <Layout>
      <Page
        title="Dashboard"
        pageTitle="Dashboard"
        action={<SearchLogs value={query} onChange={handleQueryChange} />}
      >
        {renderState.empty && (
          <Alert severity="info">
            {query !== "" ? (
              <>We couldn't find anything matching: "{query}".</>
            ) : (
              <>
                Uh oh, you have nothing to show here yet.{" "}
                <Link to="/upload/logs">Click here to analyze a log.</Link>
              </>
            )}
          </Alert>
        )}

        {renderState.errors && (
          <Alert severity="error">{getFirstError(errors)}</Alert>
        )}

        <Masonry
          columns={getColumns()}
          spacing={1}
          sx={{ marginBottom: theme.spacing(5) }}
        >
          {renderState.logs && logCards}
          {renderState.loading && loadingNodes}
        </Masonry>

        <Grid2
          sx={{
            position: isMobile ? "fixed" : "relative",
            bottom: 0,
            left: 0,
          }}
          size={{ xs: 12 }}
          textAlign="center"
        >
          <Box component={Paper} variant="outlined" padding={theme.spacing(1)}>
            {loading ? (
              <Grid2
                justifyContent="center"
                display="inline-flex"
                spacing={1}
                container
              >
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="circular" width={32} height={32} />
              </Grid2>
            ) : (
              <Pagination
                role="navigation"
                sx={{ display: "inline-block" }}
                count={page.last_page}
                page={page.current_page}
                onChange={(_, page) => handlePageChange(page)}
              />
            )}
          </Box>
        </Grid2>
      </Page>
    </Layout>
  );
}
