import dayjs from "dayjs";
import { useCallback, useContext } from "react";
import {
  AuthContext,
  AUTH_STORAGE_KEY,
  DEFAULT_AUTH_STATE,
} from "../context/auth";
import { User } from "../types/api/user";

export default function useAuth() {
  const { auth, setAuth } = useContext(AuthContext);

  const isAuthed = useCallback(() => {
    return auth.expires && dayjs(auth.expires).isAfter(dayjs());
  }, [auth.expires]);

  const setAuthed = useCallback(
    (token: string, expires: number, user: User) => {
      const authState = {
        token,
        expires: dayjs().add(expires, "seconds"),
        user,
      };

      try {
        window.localStorage.setItem(
          AUTH_STORAGE_KEY,
          JSON.stringify(authState)
        );
        setAuth(authState);
      } catch (error) {
        throw new Error("Failed to set auth state.", { cause: error });
      }
    },
    [setAuth]
  );

  const setUnauthed = useCallback(() => {
    try {
      window.localStorage.removeItem(AUTH_STORAGE_KEY);
      setAuth(DEFAULT_AUTH_STATE);
    } catch (error) {
      throw new Error("Failed to clear auth state.", { cause: error });
    }
  }, [setAuth]);

  return { auth, isAuthed, setAuthed, setUnauthed };
}
