import {
  Box,
  Container,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { DISCORD_URL } from "../constants";
import DiscordIcon from "./DiscordIcon";

export default function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link variant="body2" color="text.secondary" href="/privacy-policy">
            Privacy Policy
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link variant="body2" color="text.secondary" href="/terms-of-service">
            Terms of Service
          </Link>
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: "left", color: "text.secondary" }}
        >
          <IconButton
            color="secondary"
            size="small"
            href={DISCORD_URL}
            target="_blank"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <DiscordIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
