import { CircularProgress } from "@mui/material";
import Page from "../../components/Page";

export default function Loading() {
  return (
    <Page variant="utility">
      <CircularProgress variant="indeterminate" />
    </Page>
  );
}
