import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Drawer from "../../components/Drawer";
import Main from "../../components/Main";
import Navbar from "../../components/Navbar";
import { SearchProvider } from "../../context/search";
import useAuth from "../../hooks/use-auth";

const drawerWidth = 240;

export default function Layout({ children }: { children: React.ReactNode }) {
  const { isAuthed } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <SearchProvider>
      <Box sx={{ display: "flex" }}>
        <Navbar />
        {isAuthed() && <Drawer drawerWidth={isMobile ? 0 : drawerWidth} />}
        <Main drawerWidth={isMobile ? 0 : drawerWidth}>{children}</Main>
      </Box>
    </SearchProvider>
  );
}
