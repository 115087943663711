import {
  Box,
  Grid2,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactNode, useEffect } from "react";
import { SITE_NAME } from "../constants";
import Utility from "./Utility";

interface PageProps {
  children?: ReactNode;
  title?: string;
  pageTitle?: string;
  action?: ReactNode;
  variant?: "page" | "utility";
  loading?: boolean;
  error?: 404 | 403;
}

export default function Page(props: PageProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (props.pageTitle !== undefined) {
      document.title = `${SITE_NAME} - ${props.pageTitle}`;
    }
  }, [props.pageTitle]);

  if (props.variant === "utility") {
    return <Utility>{props.children}</Utility>;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Grid2
        container
        paddingBottom={2}
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 8 }}>
          {props.loading ? (
            <Skeleton width={"70%"} height={40} variant="text" />
          ) : (
            <Typography color="textPrimary" variant="h5">
              {props.title}
            </Typography>
          )}
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
          {props.loading ? (
            <Skeleton width="100%" height={50} variant="rounded" />
          ) : (
            props.action
          )}
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <Paper
          variant={isMobile ? "elevation" : "outlined"}
          elevation={0}
          style={{
            padding: isMobile ? 0 : theme.spacing(1),
          }}
        >
          <Grid2 container spacing={1}>
            {props.children}
          </Grid2>
        </Paper>
      </Grid2>
    </Box>
  );
}
