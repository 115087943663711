import { LineAxis, Smartphone } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Typography,
} from "@mui/material";

const features = [
  {
    title: "Powerful Charting",
    description:
      "Our charting provides a robust set of features for you to effectively analyze your data.",
    icon: <LineAxis />,
  },
  {
    title: "Mobile Ready",
    description:
      "We crafted our app to be mobile first, meaning you can get the full desktop experience from your pocket.",
    icon: <Smartphone />,
  },
];

export default function Features() {
  return (
    <Grid2 container spacing={1} paddingY={10} justifyContent="center">
      <Grid2 size={{ xs: 12 }} textAlign="center">
        <Typography variant="h5">Featuring</Typography>
      </Grid2>
      <Grid2
        container
        size={{ xs: 12 }}
        spacing={1}
        display="inline-flex"
        justifyContent="center"
      >
        {features.map((feat, idx) => (
          <Grid2 size={{ xs: 12, md: 6, lg: 4 }} key={idx}>
            <Card variant="outlined" sx={{ minHeight: 180 }}>
              <CardHeader avatar={feat.icon} title={feat.title} />
              <CardContent>
                <Typography variant="body1">{feat.description}</Typography>
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Grid2>
  );
}
