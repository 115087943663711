import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import useAxios from "./use-axios";

export default function useAuthData<T>(uri: string) {
  const axios = useAxios();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError>();
  const [data, setData] = useState<T>({} as T);

  useEffect(() => {
    let fetching = true;

    setLoading(true);
    setError(undefined);
    const controller = new AbortController();

    axios(controller.signal)
      .get(uri)
      .then(({ data }: { data: T }) => {
        if (fetching) {
          setData(data);
          setLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        if (fetching) {
          setError(error);
          setLoading(false);
        }
      });

    return () => {
      controller.abort();
      fetching = false;
    };
  }, [uri, axios]);

  return { loading, error, data };
}
