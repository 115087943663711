import { Alert, Grid2 } from "@mui/material";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";

export const EmptyErrors: Errors = {
  message: "",
  errors: {},
};

export interface Errors {
  message: string;
  errors: {
    [key: string]: string[];
  };
}

export function getErrorFromResponse(error: AxiosError, notAllowed?: string) {
  if (!error.response) {
    return makeServerError(
      "Uh oh, something went wrong on our end. Please try again later."
    );
  }

  switch (error.status) {
    case 422:
      return error.response.data as Errors;

    case 403:
      return makeServerError(
        notAllowed || "You are not allowed to perform this action."
      );

    case 404:
      return makeServerError("Sorry we couldn't find the requested resource.");

    default:
      return makeServerError(
        "Uh oh, something went wrong on our end. Please try again later."
      );
  }
}

export function handleErrors(
  setErrors: Dispatch<SetStateAction<Errors>>,
  notAllowed?: string
) {
  return function (error: AxiosError) {
    return setErrors(getErrorFromResponse(error, notAllowed));
  };
}

export function makeServerError(message: string) {
  return {
    message,
    errors: {
      server: [message],
    },
  };
}

export function hasErrors(errors: Errors) {
  return Object.keys(errors.errors).length > 0;
}

export function getFirstError(errors: Errors) {
  const keys = Object.keys(errors.errors);
  if (keys.length > 0) {
    return errors.errors[keys[0]][0];
  }

  return undefined;
}

export function getAlertForFieldName(errors: Errors, fieldName: string) {
  if (fieldNameHasError(errors as Errors, fieldName)) {
    return (
      <Grid2 size={{ xs: 12 }}>
        <Alert severity="error">
          {getErrorForFieldName(errors as Errors, fieldName)}
        </Alert>
      </Grid2>
    );
  }

  return null;
}

export function fieldNameHasError(errors: Errors, fieldName: string) {
  return errors.errors !== undefined && errors.errors[fieldName] !== undefined;
}

export function getErrorForFieldName(errors: Errors, fieldName: string) {
  if (fieldNameHasError(errors, fieldName)) {
    return errors.errors[fieldName].at(0);
  }

  return undefined;
}

export interface Paginated<T> {
  data: T[];
  total: number;
  current_page: number;
  last_page: number;
  from: number;
  to: number;
  per_page: number;
}

export function makeEmptyPaginated<T>() {
  return {
    data: [] as T,
    total: 0,
    current_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
    per_page: 0,
  };
}
