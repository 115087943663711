import { createContext, ReactNode, useState } from "react";
import Error from "../app/utilities/error";
import Loading from "../app/utilities/loading";
import useAuthData from "../hooks/use-auth-data";
import { User } from "../types/api/user";

export const UserContext = createContext({
  user: {} as User,
  refresh: () => {},
});

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [count, setCount] = useState(0);
  const {
    loading,
    error,
    data: user,
  } = useAuthData<User>("/v1/auth/self?c=" + count);

  const refresh = () => setCount(count + 1);

  if (loading && count === 0) {
    return <Loading />;
  }

  if (!loading && error) {
    return <Error />;
  }

  return (
    <UserContext.Provider value={{ user, refresh }}>
      {children}
    </UserContext.Provider>
  );
};
