import {
  Alert,
  Card,
  CardContent,
  Chip,
  Grid2,
  Skeleton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Form from "../../../components/Form";
import Page from "../../../components/Page";
import useAuth from "../../../hooks/use-auth";
import useAuthData from "../../../hooks/use-auth-data";
import { User } from "../../../types/api/user";
import Layout from "../layout";

interface updateEmailState {
  email: string;
  email_change_password_verify: string;
}

const ChangeEmailForm = ({
  email,
  email_verified_at,
}: {
  email: string;
  email_verified_at?: Date;
}) => {
  const [emailState, setEmailState] = useState(email);
  const getChipColor = () => {
    return !!email_verified_at ? "success" : "warning";
  };

  const getChipText = () => {
    return !!email_verified_at ? "Verified" : "Unverified";
  };

  return (
    <Grid2 container flexGrow={1}>
      <Grid2 size={12} justifyContent={"space-between"} container>
        <Typography variant="body1">Email</Typography>
        <Chip size="small" color={getChipColor()} label={getChipText()} />
      </Grid2>
      <Form
        url="/v1/user"
        method="patch"
        notAllowedText="Incorrect password supplied."
        successMessage="Successfully updated e-mail address."
        initialState={{
          email: emailState,
          email_change_password_verify: "",
        }}
        onSuccess={(user: User) => setEmailState(user.email)}
        validate={(state: updateEmailState) =>
          state.email !== emailState &&
          state.email_change_password_verify !== ""
        }
        fields={[
          {
            props: {
              name: "email",
              required: true,
              type: "email",
              placeholder: "me@email.com",
              autoComplete: "off",
            },
            gridWidth: 12,
          },
          {
            props: {
              label: "Password (verify)",
              name: "email_change_password_verify",
              required: true,
              type: "password",
              placeholder: "********",
              autoComplete: "off",
            },
            gridWidth: 12,
          },
        ]}
      />
    </Grid2>
  );
};

interface updateUsernameState {
  username: string;
}

const ChangeUsernameForm = ({ username }: { username: string }) => {
  const [usernameState, setUsernameState] = useState(username);

  return (
    <Form
      url="/v1/user"
      method="patch"
      successMessage="Successfully updated username."
      initialState={{
        username: usernameState,
      }}
      onSuccess={(user: User) => setUsernameState(user.username)}
      validate={(state: updateUsernameState) =>
        state.username !== usernameState
      }
      fields={[
        {
          props: {
            label: "Username",
            name: "username",
            required: true,
            type: "text",
            placeholder: "Username",
            autoComplete: "off",
          },
          gridWidth: 12,
        },
      ]}
    />
  );
};

interface updatePasswordState {
  password: string;
  new_password: string;
  new_password_again: string;
}

const ChangePasswordForm = () => {
  const { setUnauthed } = useAuth();

  const newPasswordsMustMatchValidator = (state: updatePasswordState) =>
    !!state.new_password &&
    !!state.new_password_again &&
    state.new_password === state.new_password_again;

  return (
    <>
      <Form
        url="/v1/user/password"
        method="post"
        successMessage="Successfully changed password, redirecting in 3s..."
        notAllowedText="Current password did not match."
        onSuccess={() => {
          setTimeout(setUnauthed, 3000);
        }}
        validate={(state: updatePasswordState) =>
          newPasswordsMustMatchValidator(state) &&
          state.password !== state.new_password
        }
        initialState={{
          password: "",
          new_password: "",
          new_password_again: "",
        }}
        fields={[
          {
            props: {
              label: "Current Password",
              name: "password",
              type: "password",
              placeholder: "********",
              autoComplete: "off",
              required: true,
            },
            validate: (state: updatePasswordState) => {
              if (!!state.password && state.password === state.new_password) {
                return "New password must not match old password.";
              }

              return null;
            },
            gridWidth: 12,
          },
          {
            props: {
              label: "New Password",
              name: "new_password",
              type: "password",
              placeholder: "********",
              autoComplete: "off",
              required: true,
            },
            gridWidth: 6,
          },
          {
            props: {
              label: "New Password (again)",
              name: "new_password_again",
              type: "password",
              placeholder: "********",
              autoComplete: "off",
              required: true,
            },
            gridWidth: 6,
          },
        ]}
      />
      <Alert severity="warning">This action will sign you out.</Alert>
    </>
  );
};

export default function UserSettings() {
  const { loading, error, data } = useAuthData<User>("/v1/auth/self");

  return (
    <Layout>
      <Page pageTitle="Account Settings" title="Account Settings">
        <Grid2 container spacing={1} flexGrow={1}>
          <Grid2
            size={{
              xs: 12,
              sm: 6,
              md: 4,
              lg: 3,
            }}
          >
            {loading && error === undefined && (
              <Skeleton variant="rounded" width="100%" height={460} />
            )}
            {!loading && error === undefined && (
              <Card elevation={0} variant="outlined">
                <CardContent>
                  <Typography variant="body1">Basic Information</Typography>
                  <ChangeUsernameForm username={data.username} />
                  <ChangeEmailForm
                    email={data.email}
                    email_verified_at={data.email_verified_at}
                  />
                </CardContent>
              </Card>
            )}
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 8, lg: 9 }}>
            {loading && error === undefined && (
              <Skeleton variant="rounded" width="100%" height={318} />
            )}
            {!loading && error === undefined && (
              <Card elevation={0} variant="outlined">
                <CardContent>
                  <Typography variant="body1">Security</Typography>

                  <ChangePasswordForm />
                </CardContent>
              </Card>
            )}
          </Grid2>
        </Grid2>
      </Page>
    </Layout>
  );
}
