import { useEffect } from "react";

export function calcMaxHeight(
  visualViewport: VisualViewport | null,
  defaultHeight: number
) {
  if (visualViewport !== null) {
    const height = visualViewport.height - 260;
    return height <= defaultHeight ? defaultHeight : height;
  }

  return defaultHeight;
}

export default function useViewportResize(
  callback: (viewport: VisualViewport | null) => void
) {
  useEffect(() => {
    const handleResize = () => {
      callback(visualViewport);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [callback]);
}
