import {
  Box,
  Button,
  Container,
  Grid2,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { SITE_NAME } from "../../constants";
import Features from "./Features";

export default function Marketing() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [email, setEmail] = useState("");

  useEffect(() => {
    document.title = `${SITE_NAME} - Online Tuning Tools`;
  });

  const marketingHeaderImage = useMemo(() => {
    if (theme.palette.mode === "dark") {
      return require("./marketing_image_mobile_dark.png");
    }
    return require("./marketing_image_mobile_light.png");
  }, [theme.palette.mode]);

  return (
    <Box>
      <Navbar noBurger />
      <Grid2
        container
        sx={{
          background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.background.default})`,
        }}
        padding={2}
        paddingY={10}
      >
        <Toolbar />
        <Container>
          <Grid2 container alignItems="center">
            <Grid2
              size={{ xs: 12, md: 8 }}
              textAlign={isMobile ? "center" : "left"}
            >
              <Typography color="textPrimary" variant="h2" fontWeight={500}>
                Welcome to{" "}
                <span style={{ color: theme.palette.primary.main }}>
                  Calibratly
                </span>
              </Typography>
              <Typography color="textPrimary" variant="body1">
                Use our free* suite of tools to help you in your ECU calibration
                journey.
              </Typography>
              <Grid2
                container
                spacing={1}
                justifyContent={isMobile ? "center" : "left"}
              >
                <Grid2
                  container
                  spacing={1}
                  display="inline-flex"
                  justifyContent="center"
                >
                  <TextField
                    label="E-Mail"
                    placeholder="me@email.com"
                    type="email"
                    size="small"
                    value={email}
                    onChange={(el) => setEmail(el.target.value)}
                    sx={{
                      width: 300,
                    }}
                  />
                  <Button
                    type="button"
                    href={`/signup?email=${email}`}
                    variant="contained"
                    size="small"
                  >
                    Get Started
                  </Button>
                </Grid2>
              </Grid2>
            </Grid2>
            {!isMobile && (
              <Grid2 size={{ md: 4 }} justifyContent="center">
                <img
                  style={{
                    borderWidth: 2,
                    borderRadius: 4,
                    borderColor: theme.palette.divider,
                    borderStyle: "solid",
                    maxHeight: 600,
                  }}
                  src={marketingHeaderImage}
                  alt="mobile phone chart"
                />
              </Grid2>
            )}
          </Grid2>
        </Container>
      </Grid2>
      <Container>
        <Features />
      </Container>
      <Footer />
    </Box>
  );
}
