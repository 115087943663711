import {
  Add,
  ChevronLeft,
  Insights,
  Person2Outlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Badge,
  Button,
  Drawer,
  Grid2,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavContext } from "../context/navigation";
import useAuth from "../hooks/use-auth";
import useLogo from "../hooks/use-logo";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Navbar({ noBurger }: { noBurger?: boolean }) {
  const logo = useLogo("inline-transparent");
  const { isAuthed } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { state, toggleDrawer } = React.useContext(NavContext);
  const [actionsOpen, setActionsOpen] = React.useState(false);

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        variant="outlined"
        elevation={0}
        open={state.drawerOpen && !isMobile && isAuthed()}
      >
        <Toolbar>
          {isAuthed() && !noBurger && (
            <IconButton
              onClick={toggleDrawer}
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{
                mr: 2,
                display: "flex",
                ...(state.drawerOpen && !isMobile && { display: "none" }),
              }}
            >
              {state.drawerOpen ? <ChevronLeft /> : <MenuIcon />}
            </IconButton>
          )}
          <Link
            to={isAuthed() ? "/dashboard" : "/"}
            style={{ textDecoration: "none", display: "inline-flex" }}
          >
            <Badge badgeContent="BETA" color="success">
              <img src={logo} alt="calibratly logo" height={32} />
            </Badge>
          </Link>
          <Grid2
            container
            columns={2}
            justifyContent={isMobile ? "flex-end" : "space-between"}
            size={"grow"}
          >
            <Grid2 sx={{ display: { xs: "none", md: "flex" } }}></Grid2>
            <Grid2>
              {isAuthed() && (
                <Grid2 container spacing={1}>
                  <IconButton
                    onClick={() => setActionsOpen(true)}
                    color="primary"
                  >
                    <Add />
                  </IconButton>
                  <Link
                    style={{
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main
                      ),
                    }}
                    to="/user/settings"
                  >
                    <IconButton color="default">
                      <Person2Outlined />
                    </IconButton>
                  </Link>
                </Grid2>
              )}
              {!isAuthed() && (
                <Grid2 container spacing={1}>
                  <Link
                    style={{
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main
                      ),
                    }}
                    to={`/signin${location.pathname !== "/" ? "?from=" + encodeURIComponent(`${location.pathname}${location.hash}${location.search}`) : ""}`}
                  >
                    <Button variant="text" color="primary">
                      Sign In
                    </Button>
                  </Link>

                  <Link
                    style={{
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main
                      ),
                    }}
                    to="/signup"
                  >
                    <Button variant="contained" color="primary">
                      Sign Up
                    </Button>
                  </Link>
                </Grid2>
              )}
            </Grid2>
          </Grid2>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="bottom"
        open={actionsOpen}
        onClose={() => setActionsOpen(false)}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/upload/logs")}>
              <ListItemIcon>
                <Insights />
              </ListItemIcon>
              <ListItemText>Analyze Log</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
