import { useContext, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import ResetPassword from "./app/auth/ResetPassword";
import signin from "./app/auth/signin";
import signup from "./app/auth/signup";
import Dashboard from "./app/dashboard/Dashboard";
import Logs from "./app/dashboard/logs/Logs";
import UploadLogs from "./app/dashboard/upload/logs";
import UserSettings from "./app/dashboard/user/settings";
import VerifyEmail from "./app/dashboard/user/verify-email";
import RootLayout from "./app/layout";
import Marketing from "./app/marketing/Marketing";
import PrivacyPolicy from "./app/marketing/PrivacyPolicy";
import TermsOfService from "./app/marketing/TermsOfService";
import NotFound from "./app/utilities/notfound";
import { DashboardProvider } from "./context/dashboard";
import { UserContext, UserProvider } from "./context/user";
import useAuth from "./hooks/use-auth";

function Authed({ isAuthed }: { isAuthed: boolean }) {
  const location = useLocation();

  if (!isAuthed) {
    let redirect_url = "/signin";
    if (!["/", "/dashboard"].includes(location.pathname)) {
      redirect_url += `?from=${encodeURIComponent(`${location.pathname}${location.hash}${location.search}`)}`;
    }

    return <Navigate to={redirect_url} />;
  }

  return (
    <UserProvider>
      <DashboardProvider>
        <Outlet />
      </DashboardProvider>
    </UserProvider>
  );
}

function Verified() {
  const { user } = useContext(UserContext);

  if (!user.email_verified_at) {
    return <Navigate to="/user/verify-email" />;
  }

  return <Outlet />;
}

function Guest({ isAuthed }: { isAuthed: boolean }) {
  const [params] = useSearchParams();

  if (isAuthed) {
    let redirect_url = "/dashboard";
    if (params.has("from")) {
      redirect_url = decodeURIComponent(params.get("from") || "");
    }

    return <Navigate to={redirect_url} replace />;
  }

  return <Outlet />;
}

function AppRoutes() {
  const { auth, isAuthed, setUnauthed } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthed() && !!auth.token) {
      setUnauthed();
    }
  }, [auth.token, location, isAuthed, setUnauthed]);

  return (
    <Routes>
      <Route element={<Guest isAuthed={isAuthed()} />}>
        <Route path="/signin" Component={signin} />
        <Route path="/signup" Component={signup} />
        <Route path="/reset-password/:token" Component={ResetPassword} />
      </Route>
      <Route element={<Authed isAuthed={isAuthed()} />}>
        <Route path="/user/settings" Component={UserSettings} />
        <Route
          path="/user/verify-email/:user_id?/:hash?"
          Component={VerifyEmail}
        />
        <Route element={<Verified />}>
          <Route path="/upload/logs" Component={UploadLogs} />
          <Route path="/dashboard" Component={Dashboard} />
        </Route>
      </Route>
      <Route path="/logs/:logId" Component={Logs} />
      <Route path="/" Component={Marketing} />
      <Route path="/terms-of-service" Component={TermsOfService} />
      <Route path="/privacy-policy" Component={PrivacyPolicy} />
      <Route path="*" Component={NotFound} />
    </Routes>
  );
}

export default function Router() {
  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <RootLayout>
        <AppRoutes />
      </RootLayout>
    </BrowserRouter>
  );
}
