import { Autocomplete, TextField } from "@mui/material";
import { NormalizedParam } from "../hooks/use-log-params";

export interface XAxisSelectorProps {
  selected?: NormalizedParam | null;
  onChange: (param: NormalizedParam | null) => void;
  params: NormalizedParam[];
}

export default function XAxisSelector({
  params,
  selected,
  onChange,
}: XAxisSelectorProps) {
  const handleChange = (
    _: React.SyntheticEvent,
    value: NormalizedParam | null
  ) => {
    onChange(value);
  };

  return (
    <Autocomplete
      fullWidth
      disablePortal
      options={params}
      value={selected || null}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label="X-Axis" />}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.key}
    />
  );
}
