import {
  Description,
  Edit,
  ExpandLess,
  ExpandMore,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Chip,
  Collapse,
  Grid2,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { File } from "../../types/api/files";
import { Log } from "../../types/api/logs";
import dayjs from "../../util/dayjs";
import EditLogCardModal from "./EditLogCardModal";
import LogFileItem from "./LogFileItem";

export interface LogCardProps extends CardProps {
  log: Log;
  updateLog: (log: Log) => void;
  deleteLog: (log: Log) => void;
}

function LogActions({
  id,
  toggleEdit,
}: {
  id: string;
  toggleEdit: () => void;
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/logs/${id}`);
  };

  return (
    <>
      <Tooltip title="Edit">
        <IconButton onClick={toggleEdit}>
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="View Log">
        <IconButton onClick={handleClick}>
          <RemoveRedEye />
        </IconButton>
      </Tooltip>
    </>
  );
}

function LogCardSubheader({
  date,
  isPublic,
}: {
  date: Date;
  isPublic: boolean;
}) {
  const formattedDate = () => {
    return dayjs.utc(date).fromNow();
  };

  const getChipVariant = () => {
    return isPublic ? "success" : "error";
  };

  const getChipText = () => {
    return isPublic ? "public" : "private";
  };

  return (
    <Grid2 display="inline-flex">
      <Chip size="small" label={getChipText()} color={getChipVariant()} />
      <Typography color="textSecondary">
        &nbsp;{"- "}
        {formattedDate()}
      </Typography>
    </Grid2>
  );
}

export default function LogCard(props: LogCardProps) {
  const { log, updateLog, deleteLog, ...rest } = props;
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const toggleEdit = () => {
    setEditOpen(!editOpen);
  };

  const onLogDelete = (log: Log) => {
    deleteLog(log);
    setEditOpen(false);
  };

  const onFileDelete = (file: File) => {
    const cpy: Log = {
      ...log,
      files: log.files.filter((f) => f.id !== file.id),
    };

    updateLog(cpy);
  };

  const onSuccess = (log: Log) => {
    updateLog(log);
  };

  return (
    <>
      <EditLogCardModal
        log={log}
        open={editOpen}
        handleClose={toggleEdit}
        onSuccess={onSuccess}
        onLogDelete={onLogDelete}
        onFileDelete={onFileDelete}
      />
      <Card {...rest} data-testid="log-card">
        <CardHeader
          title={log.name}
          subheader={
            <LogCardSubheader isPublic={log.public} date={log.created_at} />
          }
          action={<LogActions toggleEdit={toggleEdit} id={log.id} />}
        />
        <CardContent>
          <Typography variant="caption" color="textSecondary">
            Notes
          </Typography>
          {log.notes ? (
            <Typography variant="body1">{log.notes}</Typography>
          ) : (
            <Typography variant="body1" color="textSecondary">
              You did not make any notes for this log.
            </Typography>
          )}
          <List>
            <ListItemButton onClick={() => setOpen(!open)}>
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ListItemText primary="Files" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {log.files &&
                  log.files.map((file: File, idx: number) => (
                    <LogFileItem file={file} key={file.id} />
                  ))}
              </List>
            </Collapse>
          </List>
        </CardContent>
      </Card>
    </>
  );
}
