import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthedPages } from "../constants/navigation";

interface NavState {
  drawerOpen: boolean;
}

const initialState = {
  drawerOpen: false,
};

export const NavContext = createContext<{
  state: NavState;
  toggleDrawer: () => void;
}>({
  state: initialState,
  toggleDrawer: () => null,
});

export const NavProvider = function ({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const [state, setState] = useState<NavState>(initialState);

  // this is kinda hacky to set the initial state i guess maybe?
  // touch later no big deal rn
  useEffect(() => {
    const page = AuthedPages.get(location.pathname);
    if (page !== undefined) {
      setState((s) => ({ ...s, activePage: page }));
    }
  }, [location.pathname]);

  const toggleDrawer = () => {
    setState({
      ...state,
      drawerOpen: !state.drawerOpen,
    });
  };

  return (
    <NavContext.Provider value={{ state, toggleDrawer }}>
      {children}
    </NavContext.Provider>
  );
};
