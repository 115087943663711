import { Button, Grid2, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Page from "../../components/Page";
import useAuth from "../../hooks/use-auth";

export default function NotFound() {
  const { isAuthed } = useAuth();

  return (
    <Page variant="utility" pageTitle="Not Found">
      <Grid2 textAlign="center">
        <Grid2>
          <Typography variant="h1">404</Typography>
        </Grid2>
        <Grid2>
          <Typography variant="body1">
            The page you are looking for could not be found.
          </Typography>
        </Grid2>
        <Grid2>
          <Link to={isAuthed() ? "/dashboard" : "/"}>
            <Button variant="contained">Go Home</Button>
          </Link>
        </Grid2>
      </Grid2>
    </Page>
  );
}
