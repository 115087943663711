import SsidChartIcon from "@mui/icons-material/SsidChart";
import { Page } from "../types/navigation";

export const Dashboard = "/dashboard";

export const AuthedPages = new Map<string, Page>([
  [
    Dashboard,
    {
      title: "Dashboard",
      pathname: Dashboard,
      icon: <SsidChartIcon />,
    },
  ],
]);
