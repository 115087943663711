import { Dashboard } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import { Alert, Snackbar, SwipeableDrawer, useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DISCORD_URL } from "../constants";
import { NavContext } from "../context/navigation";
import useLogout from "../hooks/use-logout";
import DiscordIcon from "./DiscordIcon";

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const GetDrawer = (drawerWidth: number) =>
  styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme, drawerWidth),
      "& .MuiDrawer-paper": openedMixin(theme, drawerWidth),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

interface DrawerProps {
  drawerWidth: number;
}

const DrawerLogoutButton = () => {
  const { logout, loading, error } = useLogout<HTMLAnchorElement>();

  return (
    <>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          href="#"
          onClick={logout}
          data-testid="drawer-logout-button"
          disabled={loading}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </ListItem>
      <Snackbar
        open={!!error}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  );
};

function ProvideFeedbackItem() {
  return (
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        href={DISCORD_URL}
        target="_blank"
        data-testid="drawer-discord-button"
      >
        <ListItemIcon>
          <DiscordIcon />
        </ListItemIcon>
        <ListItemText primary="Give Feedback" />
      </ListItemButton>
    </ListItem>
  );
}

const pages = [
  {
    icon: <Dashboard />,
    title: "Dashboard",
    to: "/dashboard",
  },
];

export default function MiniDrawer(props: DrawerProps) {
  const Drawer = useMemo(
    () => GetDrawer(props.drawerWidth),
    [props.drawerWidth]
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { state, toggleDrawer } = useContext(NavContext);

  const listItems = useMemo(() => {
    const handleLinkClick = (to: string) => {
      navigate(to);
      if (isMobile) {
        toggleDrawer();
      }
    };

    return pages.map((page, key) => (
      <ListItem key={key} disablePadding sx={{ display: "block" }}>
        <ListItemButton
          onClick={(e) => {
            e.stopPropagation();
            handleLinkClick(page.to);
          }}
          sx={{
            minHeight: 48,
            justifyContent: state.drawerOpen ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: state.drawerOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {page.icon}
          </ListItemIcon>
          <ListItemText
            sx={{
              opacity: state.drawerOpen ? 1 : 0,
            }}
          >
            {page.title}
          </ListItemText>
        </ListItemButton>
      </ListItem>
    ));
  }, [state.drawerOpen, isMobile, navigate, toggleDrawer]);

  const drawerContent = useMemo(
    () => (
      <>
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>{listItems}</List>
        <List sx={{ marginTop: "auto" }}>
          <ProvideFeedbackItem />
          <DrawerLogoutButton />
        </List>
      </>
    ),
    [toggleDrawer, listItems]
  );

  if (isMobile) {
    return (
      <SwipeableDrawer
        variant="temporary"
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
        closeAfterTransition
        open={state.drawerOpen}
      >
        {drawerContent}
      </SwipeableDrawer>
    );
  }

  return (
    <Drawer
      variant="permanent"
      open={state.drawerOpen && props.drawerWidth > 0}
      sx={{ display: { xs: "none", md: "flex" } }}
    >
      {drawerContent}
    </Drawer>
  );
}
