import { AxiosError } from "axios";
import { createContext, Dispatch, ReactNode, useEffect, useState } from "react";
import { SetURLSearchParams, useSearchParams } from "react-router-dom";
import useAxios from "../hooks/use-axios";
import useDebounce from "../hooks/use-debounce";
import {
  EmptyErrors,
  Errors,
  getErrorFromResponse,
  makeEmptyPaginated,
  Paginated,
} from "../types/api/common";
import { Log } from "../types/api/logs";

interface logState {
  loading: boolean;
  errors: Errors;
  page: Paginated<Log>;
}

export const DashboardContext = createContext<{
  logState: logState;
  query: string | null;
  setQuery: Dispatch<string | null>;
  setParams: SetURLSearchParams;
  setPage: Dispatch<number>;
  updateLog: (log: Log) => void;
  deleteLog: (log: Log) => void;
}>({
  logState: {
    loading: true,
    errors: EmptyErrors,
    page: makeEmptyPaginated(),
  },
  query: "",
  setQuery: () => {},
  setParams: () => {},
  setPage: () => {},
  updateLog: () => {},
  deleteLog: () => {},
});

export const DashboardProvider = ({ children }: { children: ReactNode }) => {
  const axios = useAxios();
  const [params, setParams] = useSearchParams();

  const [logState, setLogState] = useState<logState>({
    loading: true,
    errors: EmptyErrors,
    page: makeEmptyPaginated(),
  });
  const [page, setPage] = useState(() => {
    if (params.has("page")) {
      const page = parseInt(params.get("page") || "1");
      return page > 1 ? page : 0;
    }
  });
  const [query, setQuery] = useState(() => {
    if (params.has("query")) {
      return params.get("query");
    }

    return "";
  });
  const search = useDebounce(query, 200);

  useEffect(() => {
    setLogState((prev) => ({
      ...prev,
      loading: true,
      errors: EmptyErrors,
    }));

    const controller = new AbortController();

    axios(controller.signal)
      .get(
        `/v1/logs?page=${page}&filter[0][field]=name&filter[0][operator]=contains&filter[0][value]=${search}`
      )
      .then(({ data }: { data: Paginated<Log> }) => {
        setLogState((prev) => ({
          ...prev,
          loading: false,
          page: data,
        }));
      })
      .catch((error: AxiosError) => {
        if (error.code === "ERR_CANCELED") {
          return;
        }

        setLogState((prev) => ({
          ...prev,
          loading: false,
          errors: getErrorFromResponse(error),
        }));
      });

    return () => {
      controller.abort();
    };
  }, [axios, page, search]);

  const updateLog = (log: Log) => {
    setLogState((prev) => {
      const idx = prev.page.data.findIndex((p) => p.id === log.id);
      prev.page.data[idx] = {
        ...prev.page.data[idx],
        ...log,
      };

      return {
        ...prev,
      };
    });
  };

  const deleteLog = (log: Log) => {
    setLogState((prev) => {
      prev.page.data = prev.page.data.filter((p) => p.id !== log.id);

      return {
        ...prev,
      };
    });
  };

  return (
    <DashboardContext.Provider
      value={{
        logState,
        query,
        setPage,
        setQuery,
        setParams,
        updateLog,
        deleteLog,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
