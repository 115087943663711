import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid2,
  TextField,
} from "@mui/material";
import axios, { AxiosError } from "axios";
import React, { useState } from "react";
import Page from "../../components/Page";
import Link from "../../components/StyledLink";
import useAuth from "../../hooks/use-auth";
import useLogo from "../../hooks/use-logo";
import { AuthenticationResponse } from "../../types/api/user";
import Layout from "../dashboard/layout";
import styles from "./layout.module.css";

interface LoginForm {
  email: string;
  password: string;
}

interface ResetState {
  email: string;
  loading: boolean;
  slowDown: boolean;
  error: boolean;
  success: boolean;
}

export default function SignIn() {
  const { setAuthed } = useAuth();
  const logo = useLogo("transparent");
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState<"signin" | "forgot">("signin");

  const [resetState, setResetState] = useState<ResetState>({
    email: "",
    slowDown: false,
    loading: false,
    error: false,
    success: false,
  });

  const [error, setError] = useState("");
  const [form, setForm] = useState<LoginForm>({
    email: "",
    password: "",
  });

  const handleSubmit = (el: React.FormEvent<HTMLFormElement>) => {
    el.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);
    setError("");

    axios
      .post(`${window.config.API_URL}/v1/auth/login`, form)
      .then(({ data }: { data: AuthenticationResponse }) => {
        setAuthed(data.access_token, data.expires_in, data.user);
      })
      .catch((error: AxiosError) => {
        switch (error.status) {
          case 401:
            setError("Could not sign in with the provided credentials.");
            break;
          default:
            setError(
              "There was an error trying to sign in, please try again later."
            );
            break;
        }

        setForm({
          ...form,
          password: "",
        });
        setLoading(false);
      });
  };

  const handleInputChange = (el: React.ChangeEvent<HTMLInputElement>) => {
    el.preventDefault();
    setForm({
      ...form,
      [el.target.name]: el.target.value,
    });
  };

  const requestPasswordReset = (el: React.FormEvent<HTMLFormElement>) => {
    el.preventDefault();

    if (resetState.success) {
      return false;
    }

    setResetState((prev) => ({
      ...prev,
      loading: true,
      success: false,
      slowDown: false,
      error: false,
    }));

    axios
      .post(`${window.config.API_URL}/v1/auth/request-password-reset`, {
        email: resetState.email,
      })
      .then(() => {
        setResetState((prev) => ({
          ...prev,
          success: true,
          loading: false,
        }));
      })
      .catch((error: AxiosError) => {
        setResetState((prev) => ({
          ...prev,
          slowDown: error.status === 429,
          error: error.status !== 429,
          loading: false,
        }));
      });
  };

  return (
    <Layout>
      <Container>
        <Page variant="utility" pageTitle="Sign In">
          <Grid2
            container
            size={{ xs: 12 }}
            spacing={1}
            justifyContent="center"
          >
            <Grid2 size={{ xs: 12, sm: 8, md: 6 }}>
              <Grid2 container size={{ xs: 12 }} justifyContent="center">
                <img src={logo} width={128} alt="calibratly logo" />
              </Grid2>
              <Card variant="outlined">
                <CardHeader
                  sx={{ textAlign: "left" }}
                  title={mode === "signin" ? "Sign In" : "Reset Password"}
                />
                {mode === "signin" ? (
                  <form onSubmit={handleSubmit}>
                    <CardContent>
                      <Grid2 container rowSpacing={2}>
                        <Grid2 size={{ xs: 12 }}>
                          <TextField
                            className={styles.input}
                            id="identity"
                            label="E-Mail"
                            placeholder="me@email.com"
                            type="email"
                            name="email"
                            onChange={handleInputChange}
                            value={form.email}
                            required
                            disabled={loading}
                          />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                          <TextField
                            className={styles.input}
                            id="password"
                            label="Password"
                            placeholder="********"
                            type="password"
                            name="password"
                            onChange={handleInputChange}
                            value={form.password}
                            required
                            disabled={loading}
                          />
                        </Grid2>
                      </Grid2>
                    </CardContent>
                    {error && (
                      <CardActions>
                        <Grid2 size={{ xs: 12 }} justifyContent="space-around">
                          <Grid2>
                            <Alert severity="error">{error}</Alert>
                          </Grid2>
                        </Grid2>
                      </CardActions>
                    )}
                    <CardActions>
                      <Grid2 container size={12} justifyContent="space-between">
                        <Grid2>
                          <LoadingButton
                            variant="contained"
                            disabled={loading}
                            loading={loading}
                            size="small"
                            type="submit"
                          >
                            Lets Go
                          </LoadingButton>
                        </Grid2>
                        <Grid2>
                          <Button variant="text" size="small">
                            <Link sx={{ textDecoration: "none" }} to="/signup">
                              Need an account?
                            </Link>
                          </Button>
                        </Grid2>
                      </Grid2>
                    </CardActions>
                  </form>
                ) : (
                  <form onSubmit={requestPasswordReset}>
                    <CardContent>
                      <Grid2 container rowSpacing={2}>
                        <Grid2 size={{ xs: 12 }}>
                          <TextField
                            className={styles.input}
                            id="identity"
                            label="E-Mail"
                            placeholder="me@email.com"
                            type="email"
                            name="email"
                            onChange={(el) =>
                              setResetState((prev) => ({
                                ...prev,
                                email: el.target.value,
                              }))
                            }
                            value={resetState.email}
                            required
                            disabled={resetState.loading || resetState.success}
                          />
                        </Grid2>
                      </Grid2>
                    </CardContent>
                    {resetState.success && (
                      <CardActions>
                        <Grid2 size={{ xs: 12 }} justifyContent="space-around">
                          <Grid2>
                            <Alert severity="success">
                              Please check your email for a link to reset your
                              password.
                            </Alert>
                          </Grid2>
                        </Grid2>
                      </CardActions>
                    )}
                    {(resetState.error || resetState.slowDown) && (
                      <CardActions>
                        <Grid2 size={{ xs: 12 }} justifyContent="space-around">
                          <Grid2>
                            <Alert
                              severity={
                                resetState.slowDown ? "warning" : "error"
                              }
                            >
                              {resetState.error &&
                                "An error occurred while requesting a reset, please try again later."}
                              {resetState.slowDown &&
                                "Slow down! You have requested too many resets too recently."}
                            </Alert>
                          </Grid2>
                        </Grid2>
                      </CardActions>
                    )}
                    <CardActions>
                      <Grid2 container size={12} justifyContent="space-between">
                        <Grid2>
                          <LoadingButton
                            variant="contained"
                            disabled={resetState.loading || resetState.success}
                            loading={resetState.loading}
                            size="small"
                            type="submit"
                          >
                            Request Reset
                          </LoadingButton>
                        </Grid2>
                      </Grid2>
                    </CardActions>
                  </form>
                )}
              </Card>
            </Grid2>
          </Grid2>
          <Grid2
            container
            size={{ xs: 12 }}
            justifyContent="center"
            padding={1}
          >
            <Button
              onClick={() => setMode(mode === "signin" ? "forgot" : "signin")}
              variant="text"
              size="small"
            >
              {mode === "signin" ? "Forgot Password?" : "Go Back"}
            </Button>
          </Grid2>
        </Page>
      </Container>
    </Layout>
  );
}
