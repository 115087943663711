import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid2,
  Link as StyledLink,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Turnstile, { useTurnstile } from "react-turnstile";
import Page from "../../components/Page";
import Link from "../../components/StyledLink";
import useLogo from "../../hooks/use-logo";
import { EmptyErrors, Errors } from "../../types/api/common";
import Layout from "../dashboard/layout";
import styles from "./layout.module.css";

interface SignUpForm {
  username: string;
  email: string;
  password: string;
  password_again: string;
  captcha_response: string;
}

const fields: { [key: string]: string }[] = [
  {
    name: "username",
    placeholder: "Username",
    label: "Username",
    type: "text",
  },
  {
    name: "email",
    placeholder: "me@domain.com",
    label: "E-Mail",
    type: "email",
  },
  {
    name: "password",
    placeholder: "********",
    label: "Password",
    type: "password",
  },
  {
    name: "password_again",
    placeholder: "********",
    label: "Password (again)",
    type: "password",
  },
];

export default function SignUp() {
  const logo = useLogo("transparent");
  const theme = useTheme();
  const [params] = useSearchParams();
  const [form, setForm] = useState<SignUpForm>({
    username: "",
    email: params.get("email") || "",
    password: "",
    password_again: "",
    captcha_response: "",
  });
  const [errors, setErrors] = useState<Errors>(EmptyErrors);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const turnstile = useTurnstile();

  const handleInputChange = (el: React.ChangeEvent<HTMLInputElement>) => {
    el.preventDefault();
    setForm({
      ...form,
      [el.target.name]: el.target.value,
    });
  };

  const handleFormSubmit = (el: React.FormEvent<HTMLFormElement>) => {
    el.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    setErrors(EmptyErrors);

    axios
      .post(`${window.config.API_URL}/v1/auth/register`, form)
      .then(() => {
        setSuccess(true);
      })
      .catch(({ response: { data } }: { response: { data: Errors } }) => {
        setErrors({
          ...EmptyErrors,
          ...data,
        });
        setForm({
          ...form,
          password: "",
          password_again: "",
        });

        setLoading(false);
        turnstile.reset();
      });
  };

  const handleTurnstileVerify = (captcha_response: string) => {
    setForm({
      ...form,
      captcha_response,
    });
  };

  const getError = (field: string) => {
    if (Object.hasOwn(errors.errors, field) && errors.errors[field].length) {
      return errors.errors[field][0];
    }
  };

  return (
    <Layout>
      <Container>
        <Page variant="utility" pageTitle="Sign Up">
          <Grid2
            container
            size={{ xs: 12, sm: 8, md: 6 }}
            justifyContent="center"
            spacing={1}
          >
            <Grid2 container size={{ xs: 12 }} justifyContent="center">
              <img src={logo} width={128} alt="calibratly logo" />
            </Grid2>
            <Card variant="outlined">
              <CardHeader sx={{ textAlign: "left" }} title="Sign Up" />
              {!success && (
                <form onSubmit={handleFormSubmit}>
                  <CardContent>
                    <Grid2 container rowSpacing={2}>
                      {fields.map((field, idx) => (
                        <React.Fragment key={idx}>
                          <Grid2 size={{ xs: 12 }}>
                            <TextField
                              disabled={loading}
                              className={styles.input}
                              id={field.name}
                              label={field.label}
                              placeholder={field.placeholder}
                              type={field.type}
                              name={field.name}
                              value={form[field.name as keyof SignUpForm]}
                              required
                              onChange={handleInputChange}
                              error={!!getError(field.name)}
                            />
                          </Grid2>
                          {!!getError(field.name) && (
                            <Grid2 size={{ xs: 12 }}>
                              <Alert severity="error">
                                {getError(field.name)}
                              </Alert>
                            </Grid2>
                          )}
                        </React.Fragment>
                      ))}
                      <Grid2 container>
                        <Typography variant="caption">
                          By signing up you agree to our{" "}
                          <StyledLink
                            href="/terms-of-service"
                            variant="caption"
                            color="textSecondary"
                          >
                            terms of service.
                          </StyledLink>
                        </Typography>
                      </Grid2>
                    </Grid2>
                  </CardContent>
                  <CardActions>
                    <Grid2 container flexGrow={1}>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Grid2 paddingBottom={1}>
                          <Turnstile
                            fixedSize
                            theme={theme.palette.mode}
                            sitekey={window.config.TURNSTILE_SITE_KEY}
                            onVerify={handleTurnstileVerify}
                          />
                        </Grid2>
                        {getError("turnstile") && (
                          <Grid2 paddingBottom={1} size={{ xs: 12 }}>
                            <Alert severity="error">
                              {getError("turnstile")}
                            </Alert>
                          </Grid2>
                        )}
                      </Grid2>
                      <Grid2 container justifyContent="space-between" size={12}>
                        <Grid2>
                          <LoadingButton
                            loading={loading}
                            disabled={loading}
                            variant="contained"
                            size="small"
                            type="submit"
                          >
                            Get Started
                          </LoadingButton>
                        </Grid2>
                        <Grid2>
                          <Button variant="text" size="small">
                            <Link sx={{ textDecoration: "none" }} to="/signin">
                              Have an account?
                            </Link>
                          </Button>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </CardActions>
                </form>
              )}
              {success && (
                <CardContent>
                  <Alert severity="success">
                    You have signed up successfully!
                    <br />
                    You may now{" "}
                    <Link sx={{ textDecoration: "none" }} to="/signin">
                      sign in.
                    </Link>
                  </Alert>
                </CardContent>
              )}
            </Card>
          </Grid2>
        </Page>
      </Container>
    </Layout>
  );
}
