import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  alpha,
  AppBar,
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid2,
  IconButton,
  List,
  Modal,
  Paper,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { flushSync } from "react-dom";
import DeleteDialog from "../../components/DeleteDialog";
import useAxios from "../../hooks/use-axios";
import {
  EmptyErrors,
  Errors,
  fieldNameHasError,
  getAlertForFieldName,
  getErrorForFieldName,
  handleErrors,
} from "../../types/api/common";
import { File } from "../../types/api/files";
import { Log } from "../../types/api/logs";
import LogFileItem from "./LogFileItem";

function FileDeleteDialog({
  fileDeleteDialog,
  unsetFileDeleteDialog,
  handleFileDelete,
}: {
  fileDeleteDialog: File;
  unsetFileDeleteDialog: () => void;
  handleFileDelete: (file: File) => void;
}) {
  const theme = useTheme();

  return (
    <DeleteDialog
      open={!!fileDeleteDialog}
      title="Delete File"
      handleCancel={unsetFileDeleteDialog}
      handleConfirm={() => handleFileDelete(fileDeleteDialog)}
      body={
        <Grid2 container spacing={1}>
          <Typography>
            To delete this file acknowledge that you understand this action is
            irreversible.
          </Typography>
          <Box
            sx={{
              backgroundColor: alpha(theme.palette.error.main, 0.2),
              borderRadius: 1,
              border: `1px solid ${theme.palette.error.main}`,
              flexGrow: 1,
            }}
          >
            <Typography padding={1} variant="overline" color="error">
              {fileDeleteDialog?.name}
            </Typography>
          </Box>
        </Grid2>
      }
    />
  );
}

function LogDeleteDialog({
  open,
  toggleDialog,
  handleDelete,
}: {
  open: boolean;
  toggleDialog: () => void;
  handleDelete: () => void;
}) {
  return (
    <DeleteDialog
      open={open}
      title="Delete File"
      handleCancel={toggleDialog}
      handleConfirm={() => handleDelete()}
      body={
        <Grid2 container spacing={1}>
          <Typography>
            Deleting this log will delete all of the files and any derived data
            associated with it.
          </Typography>
        </Grid2>
      }
    />
  );
}

export default function EditLogCardModal({
  open,
  handleClose,
  log,
  onSuccess,
  onLogDelete,
  onFileDelete,
}: {
  open: boolean;
  handleClose: () => void;
  log: Log;
  onSuccess: (log: Log) => void;
  onLogDelete: (log: Log) => void;
  onFileDelete: (file: File) => void;
}) {
  const axios = useAxios();
  const [fileDeleteDialog, setFileDeleteDialog] = useState<File>();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>(EmptyErrors);
  const [form, setForm] = useState({
    name: log.name,
    notes: log.notes,
    public: log.public ? 1 : 0,
  });

  const handleSubmit = (el: FormEvent<HTMLFormElement>) => {
    el.preventDefault();
    setLoading(true);
    setErrors(EmptyErrors);
    setSuccess("");

    axios()
      .patch(`/v1/logs/${log.id}`, form)
      .then(({ data }: { data: Log }) => {
        onSuccess(data);
        setSuccess("Successfully updated log meta.");
      })
      .catch(
        handleErrors(
          setErrors,
          "You are not allowed to change the state of this log."
        )
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setDeleteLoading(true);
    setErrors(EmptyErrors);
    setSuccess("");

    axios()
      .delete(`/v1/logs/${log.id}`)
      .then(() => {
        onLogDelete(log);
      })
      .catch(
        handleErrors(
          setErrors,
          `You are not allowed to delete the log ${log.name}.`
        )
      )
      .finally(() => {
        flushSync(() => {
          setDeleteDialog(false);
          setDeleteLoading(false);
        });
      });
  };

  const unsetFileDeleteDialog = () => {
    setFileDeleteDialog(undefined);
  };

  const handleFileDelete = (file: File) => {
    setSuccess("");
    axios()
      .delete(`/v1/files/${file.id}`)
      .then(() => {
        if (onFileDelete) {
          onFileDelete(file);
        }
        setSuccess("Successfully deleted file from log.");
      })
      .catch(
        handleErrors(
          setErrors,
          `You are not allowed to delete the file ${file.name}.`
        )
      )
      .finally(() => {
        unsetFileDeleteDialog();
      });
  };

  const handleChange = (el: ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      [el.target.name]: el.target.value,
    }));
  };

  const handleCheckbox = (el: ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      [el.target.name]: el.target.checked ? 1 : 0,
    }));
  };

  const getAlertForField = useCallback(
    (fieldName: string) => getAlertForFieldName(errors, fieldName),
    [errors]
  );

  const toggleDeleteDialog = () => {
    setDeleteDialog(!deleteDialog);
  };

  return (
    <Modal open={open} onClose={handleClose} sx={{ overflowY: "scroll" }}>
      <Box
        component={Paper}
        sx={{
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <AppBar
          position="fixed"
          color="default"
          variant="outlined"
          elevation={0}
        >
          <Toolbar>
            <Grid2
              container
              flexGrow={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                sx={{
                  maxWidth: `calc(100vw - 100px)`,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography variant="inherit">
                  Editing <b>{log.name}</b>
                </Typography>
              </Box>
              <Box>
                <IconButton title="Close Editing" onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </Grid2>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Container>
          <Grid2 container spacing={1} padding={1} justifyContent="center">
            <Box marginTop={2} padding={1} component={Paper} variant="outlined">
              <Grid2 container>
                <form
                  onSubmit={handleSubmit}
                  aria-label="form"
                  id="upload-log-form"
                >
                  <Grid2 container spacing={1}>
                    <Grid2 container flexGrow={1} paddingY={1} spacing={1}>
                      <Grid2 size={{ xs: 12 }}>
                        <TextField
                          type="text"
                          name="name"
                          label="Log Name"
                          placeholder="Log Name"
                          title="Log Name"
                          onChange={handleChange}
                          value={form.name}
                          required
                          disabled={loading}
                          error={fieldNameHasError(errors as Errors, "name")}
                        />
                      </Grid2>
                      {getAlertForField("name")}
                      <Grid2 size={{ xs: 12 }}>
                        <TextField
                          multiline
                          minRows={5}
                          title="Log Notes"
                          label="Log Notes"
                          name="notes"
                          onChange={handleChange}
                          value={form.notes || ""}
                          placeholder="Log Notes (optional)"
                          disabled={loading}
                          error={fieldNameHasError(errors as Errors, "notes")}
                        />
                      </Grid2>
                      {getAlertForField("notes")}
                      <Grid2 size={{ xs: 12 }}>
                        <Divider textAlign="left">
                          <Typography variant="body1">Features</Typography>
                        </Divider>
                      </Grid2>
                      <Grid2 container spacing={1} flexGrow={1}>
                        <Grid2 size={{ sm: 6, md: 4 }}>
                          <Tooltip
                            title="Viewable by anyone."
                            placement="right"
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={form.public === 1}
                                  onChange={handleCheckbox}
                                  name="public"
                                  disabled={loading}
                                />
                              }
                              label="Public"
                            />
                          </Tooltip>
                        </Grid2>
                      </Grid2>
                      <Grid2 container size={{ xs: 12 }}>
                        <Grid2 size={{ xs: 12 }}>
                          <Typography variant="body1">Files</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                          <Paper variant="outlined">
                            <List>
                              {log.files.map((file, key) => (
                                <LogFileItem
                                  key={key}
                                  file={file}
                                  action={
                                    <IconButton
                                      disabled={log.files.length === 1}
                                      onClick={() => setFileDeleteDialog(file)}
                                    >
                                      <Close />
                                    </IconButton>
                                  }
                                />
                              ))}
                            </List>
                          </Paper>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                    {fieldNameHasError(errors, "server") && (
                      <Grid2 size={{ xs: 12 }}>
                        <Alert severity="error">
                          {getErrorForFieldName(errors, "server")}
                        </Alert>
                      </Grid2>
                    )}

                    {success !== "" && (
                      <Grid2 size={{ xs: 12 }}>
                        <Alert severity="success">{success}</Alert>
                      </Grid2>
                    )}

                    <Grid2 container size={{ xs: 12 }} spacing={1}>
                      <LoadingButton
                        disabled={loading || deleteLoading}
                        loading={loading}
                        variant="contained"
                        type="submit"
                      >
                        Update
                      </LoadingButton>
                      <LoadingButton
                        variant="contained"
                        color="error"
                        disabled={loading || deleteLoading}
                        loading={deleteLoading}
                        onClick={() => toggleDeleteDialog()}
                      >
                        Delete Log
                      </LoadingButton>
                    </Grid2>
                  </Grid2>
                </form>
              </Grid2>
            </Box>
          </Grid2>
        </Container>
        {fileDeleteDialog !== undefined && (
          <FileDeleteDialog
            fileDeleteDialog={fileDeleteDialog}
            unsetFileDeleteDialog={unsetFileDeleteDialog}
            handleFileDelete={handleFileDelete}
          />
        )}

        <LogDeleteDialog
          toggleDialog={toggleDeleteDialog}
          handleDelete={handleDelete}
          open={deleteDialog}
        />
      </Box>
    </Modal>
  );
}
