import { CallSplit, LinearScale, RestartAlt } from "@mui/icons-material";
import { Grid2, IconButton, Skeleton } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useSearchParams } from "react-router-dom";
import LogChart from "../../../components/LogChart";
import ParameterSelector from "../../../components/ParameterSelector";
import XAxisSelector from "../../../components/XAxisSelector";
import useLogParams, { NormalizedParam } from "../../../hooks/use-log-params";
import { File } from "../../../types/api/files";
import { reduceSeriesKeys } from "../../../types/api/series";

export interface ChartContainerProps {
  loading: boolean;
  file: File;
  maxHeight: number;
}

export default function ChartContainer(props: ChartContainerProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, file, maxHeight } = props;
  const [autoScale, setAutoScale] = useState(() => {
    if (searchParams.has("auto_scale")) {
      return searchParams.get("auto_scale") === "true";
    }
    return true;
  });
  const [params, setParams] = useState<NormalizedParam[]>();
  const [xAxis, setXAxis] = useState<NormalizedParam | undefined | null>();
  const [split, setSplit] = useState(() => {
    return searchParams.get("split") === "true";
  });

  const seriesKeys = useMemo(() => {
    const keys = reduceSeriesKeys(file?.data || []);
    return Array.from(keys);
  }, [file?.data]);

  const { normalized, defaultYAxis, defaultXAxis } = useLogParams(seriesKeys);

  useEffect(() => {
    if (params === undefined && defaultYAxis.length > 0) {
      const axis = searchParams.get("yAxis");
      if (axis !== null) {
        // @ts-ignore
        const params: NormalizedParam[] = axis
          .split(",")
          .map((param) => normalized.at(parseInt(param)))
          .filter((param) => param !== undefined);
        setParams(params.filter((param) => param !== undefined));
      } else {
        setParams(defaultYAxis);
      }
    }

    if (xAxis === undefined) {
      setXAxis(
        normalized.find((key) => key.key === searchParams.get("xAxis")) ||
          defaultXAxis
      );
    }
  }, [normalized, searchParams, params, xAxis, defaultXAxis, defaultYAxis]);

  const charts = useMemo(() => {
    if (!split) {
      return (
        <LogChart
          data={file.data || []}
          params={params || []}
          autoScale={autoScale}
          xAxis={xAxis || undefined}
          containerProps={{
            aspect: 2.3,
            width: "100%",
            maxHeight,
          }}
          legend
        />
      );
    }

    return params?.map((param) => (
      <Grid2 key={param.key} size={{ xs: 12, md: 6, lg: 4 }}>
        <LogChart
          data={file.data || []}
          params={[param]}
          xAxis={xAxis || undefined}
          containerProps={{
            aspect: 1.7,
            width: "100%",
            maxHeight,
          }}
          legend
          syncId={file.id}
        />
      </Grid2>
    ));
  }, [autoScale, file.data, maxHeight, params, split, xAxis, file.id]);

  const handleAutoScaleClick = () => {
    setSearchParams((prev) => {
      prev.set("auto_scale", String(!autoScale));
      return prev;
    });
    setAutoScale(!autoScale);
  };

  const handleSplitClick = () => {
    setSearchParams((prev) => {
      prev.set("split", String(!split));
      return prev;
    });
    setSplit(!split);
  };

  const handleXAxisChange = (param: NormalizedParam | null) => {
    setSearchParams((prev) => {
      if (param === null) {
        prev.delete("xAxis");
      } else {
        prev.set("xAxis", param.key);
      }

      return prev;
    });
    setXAxis(param);
  };

  const handleParamsChange = (params: NormalizedParam[]) => {
    setSearchParams((prev) => {
      const indicies = params.map((param) =>
        normalized.findIndex((o) => o.key === param.key)
      );
      prev.set("yAxis", indicies.join(","));

      return prev;
    });

    setParams(params);
  };

  const handleResetOptions = () => {
    flushSync(() => {
      setSearchParams((prev) => {
        prev.delete("yAxis");
        prev.delete("xAxis");
        prev.delete("auto_scale");
        prev.delete("split");
        return prev;
      });

      setParams(defaultYAxis);
      setXAxis(defaultXAxis);
      setAutoScale(true);
      setSplit(false);
    });
  };

  return (
    <Grid2 size={{ xs: 12 }}>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid2 container spacing={1} size={{ xs: 12, sm: 12, md: 6 }}>
          {!loading ? (
            <XAxisSelector
              params={normalized}
              selected={xAxis}
              onChange={handleXAxisChange}
            />
          ) : (
            <Skeleton variant="rounded" height={50} width="100%" />
          )}
        </Grid2>
        <Grid2
          container
          spacing={1}
          size={{ xs: 12, sm: 12, md: 6 }}
          justifyContent="flex-end"
        >
          {!loading ? (
            <>
              <IconButton
                title="Toggle Split Charts"
                onClick={handleSplitClick}
                color={split ? "success" : "default"}
                id="toggle-chart-split"
              >
                <CallSplit />
              </IconButton>
              <IconButton
                title="Toggle Auto Scale"
                onClick={handleAutoScaleClick}
                color={autoScale ? "success" : "default"}
                id="toggle-chart-auto-scale"
              >
                <LinearScale />
              </IconButton>
              <ParameterSelector
                params={normalized}
                value={params || []}
                onChange={handleParamsChange}
              />
              <IconButton
                title="Reset Chart Options"
                id="reset-chart-options"
                onClick={handleResetOptions}
                disabled={searchParams.size === 0}
              >
                <RestartAlt />
              </IconButton>
            </>
          ) : (
            <>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="circular" width={40} height={40} />
            </>
          )}
        </Grid2>
        <Grid2
          container
          justifyContent="center"
          width="100%"
          sx={{
            touchAction: "pan-y",
          }}
        >
          {!loading ? (
            charts
          ) : (
            <Skeleton variant="rounded" width="100%" height={maxHeight} />
          )}
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
